













































import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/shared/Layout.vue';

@Component({
  components: {
    Layout,
  },
})
export default class Page404 extends Vue {}
